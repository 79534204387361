<template>
  <div>
    <label for="email" class="block text-sm font-medium text-gray-700">
      {{ label }}
    </label>
    <div class="mt-1">
      <input
        :type="type"
        :required="required"
        :placeholder="label"
        :value="value"
        @input="$emit('updated', $event)"
        class="
          appearance-none
          block
          w-full
          px-3
          py-2
          border border-gray-300
          rounded-md
          shadow-sm
          placeholder-gray-400
          focus:outline-none focus:ring-indigo-500 focus:border-indigo-500
          sm:text-sm
        "
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "text",
    },
    value: {
      type: String,
      default: "x",
    },
    required: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
